.input {
  width: 100%;

  :global(input) {
    border-radius: 0.25rem;

    &:focus {
      border: 2px solid var(--color-bg-input) !important;
    }
  }

  .label {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
    display: block;
  }
}

.containerStep {
  width: 100%;
  max-height: 1000px;
  min-height: 450px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
}

.container {
  padding: 1rem 2rem;
  background-color: #2d2d2d;
  border-radius: 0.5rem;
}

.background {
  position: sticky;
  bottom: 0;
  padding-top: 2rem;
  background: linear-gradient(transparent, #1f1f1f, #1f1f1f);
}

.add {
  padding: 1rem 2rem;
  background-color: #2d2d2d;
  border-radius: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 1rem;
}

.button {
  cursor: pointer;
  margin: 0;
  border-radius: 0.25rem;
  background-color: #9b9b9b;
  color: var(--color-text);
  padding: 13px;
  font-size: 1rem;
  font-weight: normal;
}

input[type='file'] {
  display: none;
}

.img {
  margin-left: 8px;
}

.library {
  padding: 6px 14px;
}
