@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
* {
  // box-sizing: border-box;
  margin: 0;
  // padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  overflow: hidden auto;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  font-family: 'Open Sans', sans-serif;
  color: var(--color-text);
}

main {
  background-color: var(--color-bg-1);
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

:root {
  font-family: 'Open Sans', sans-serif !important;
  color: var(--color-text) !important;
}

*::-webkit-scrollbar-track {
  background-color: inherit;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  background-color: inherit;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-level-4);
  border-radius: 1rem;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  letter-spacing: -0.03em;
  margin: 0;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 3.75rem; //* 60px = 3.75rem
  letter-spacing: -0.03em;
  margin: 0;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem; //* 20px = 1.25rem
  line-height: 60px;
}

p,
span,
a {
  font-style: normal;
  font-weight: normal;
  color: var(--color-text);
}

a {
  color: var(--color-primary-default);
}

button {
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px; //* 20px = 1.25rem
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
}

img {
  display: block;
  max-width: 100%;
}

select,
input,
textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem; //* 20px = 1.25rem
  letter-spacing: -0.02em;
  color: rgba($color: #fff, $alpha: 0.5);
}

.load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem auto;

  &.tall {
    margin: 10rem auto;
  }
}

body {
  abbr {
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    color: white;
    width: 100%;
    text-align: center;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
    cursor: default !important;
  }

  .react-date-picker__inputGroup,
  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__wrapper {
    border: none;
  }

  .react-date-picker__calendar-button__icon {
    opacity: 0;
  }

  .react-date-picker__calendar-button {
    background-color: transparent;
  }

  .react-calendar {
    position: relative;
    z-index: 1000;
    width: 350px;
    max-width: 100%;
    font-family: 'Open Sans', sans-serif;
    padding: 20px;
    background: #3d3d3d;
    box-shadow: 0px 2.24691px 17.9753px rgba(170, 170, 170, 0.03);
    border-radius: 0.5rem;
    border: none;
  }

  .react-calendar__navigation {
    height: 44px;
    width: 100%;
    display: flex;
    margin-bottom: 1em;
  }

  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: transparent !important;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .react-calendar__navigation__arrow {
    color: white;
  }

  .react-daterange-picker {
    display: inline-flex;
    position: relative;
    height: fit-content;
  }
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-daterange-picker--disabled {
    background-color: #fff;
    color: #6d6d6d;
  }
  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    height: 40px;
  }
  .react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
    color: gray;
  }
  .react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-daterange-picker__button:enabled {
    cursor: pointer;
  }
  .react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    border-width: 0;
  }
  .react-daterange-picker__calendar--closed {
    display: none;
  }

  .react-daterange-picker__calendar--open {
    margin-top: 8px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 0.5em;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.5em;
    background: none;
  }
  .react-calendar__tile--now {
    background-color: var(--color-bg-1);
    border-radius: 50%;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus,
  .react-calendar__tile--active,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: var(--color-primary-default);
    height: 40px;
  }
}

div[id^='font-picker'] {
  background-color: transparent !important;
  width: 320px !important;
  border-bottom: 1px solid white !important;
}

div[id^='font-picker'] .dropdown-button {
  background-color: transparent !important;
  width: 320px !important;
}

div[id^='font-picker'] .dropdown-icon.finished::before {
  background-color: transparent !important;
  border-top: 6px solid white !important;
}

div[id^='font-picker'] ul {
  background-color: var(--color-gray-1) !important;
}

div[id^='font-picker'] ul li button:hover,
div[id^='font-picker'] ul li button:focus,
div[id^='font-picker'] ul li button.active-font {
  background-color: var(--color-gray-2) !important;
}
