:root {
  --color-bg-1: #161616;
  --color-bg-2: #1f1f1f;
  --color-bg-3: #000000;
  --color-bg-input: #080808;
  --color-level-1: #161616;
  --color-level-2: #3d3a3a;
  --color-level-3: #5b5757;
  --color-level-4: #474747;
  --color-inverse-default: #ffffff;
  --color-inverse-dark: #e6e6e6;
  --color-primary-dark: #37bd42;
  --color-primary-default: #59cf63;
  --color-danger-dark: #b13f3f;
  --color-danger-default: #eb5757;
  --color-info-dark: #474747;
  --color-info-default: #797979;
  --color-text: #ffffff;
  --color-text-input: #838383;
  --color-text-button: #000000;
  --color-text-button-inverse: #ffffff;
  --color-gray-1: #333333;
  --color-gray-2: #3d3d3d;
}
