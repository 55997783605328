.image {
  height: 16rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.5rem;
}

.button-container {
  display: flex;

  :global(button + button) {
    margin-left: 1rem;
  }
}
