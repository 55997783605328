.svg {
  display: flex;
  align-items: center;
  justify-content: center;

  &.cursor {
    cursor: pointer;
  }

  :global(svg) {
    display: block;
  }
}
